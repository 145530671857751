<template>
    <div></div>
</template>

<script>

export default {
    name: 'Redirect',
    data: () => ({
        url: '',
        accountId: ''
    }),
    mounted () 
    {
        this.url = this.$route.params.url;
        this.accountId = this.$route.params.accountId;
        this.redirectUser();
    },
    methods: {
        redirectUser()
        {
            this.$store.dispatch('emailTrack', {
                accountId: this.accountId,
                url: this.url
            })
            .then(data => {
                window.location = "https://" + data;
            })
            .catch(() => {
                window.location = "https://" + this.url;
            })
        }
    }
}
</script>
